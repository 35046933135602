import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { common, THERAPIST_KOBITTA_VALUE, THERAPIST_REHANA_VALUE } from '../consts';
import Typography from '@material-ui/core/Typography';
import Rehana from '../resources/rehana.jpg';
import Kobitta from '../resources/kobitta-about-us.jpg';

const useStyles = makeStyles(theme => ({
    pageContainer: {
        // marginBottom: 50
    }
}));

const texts = {
    introductionHeader: 'Introduction',
    specialtiesHeader: 'Service Specialities',
    educationHeader: 'Education',
    licensesHeader: 'Licenses',
    experienceHeader: 'Clinical Experience',
    approachHeader: 'Therapeutic Approach',
    additionalQualificationsHeader: 'Additional Qualifications',
    bookingInfoHeader: 'Booking Info'
};

const REHANA_ABOUT_US_DATA = {
    introduction: '​Hello my name is Rehana Saleem. I am a licensed Mental Health Therapist. My belief is that we all need someone to talk to, someone who can really listen and accept us as we are, without judgment. I also believe that good treatment consists of establishing excellent communication and trust in a secure environment. I approach counseling and psychotherapy from a nonjudgmental, empathetic, logical, warmth, person-centered perspective.',
    specialties: [
        'Substance and Alcohol-related Issues',
        'College and Career Counseling',
        'Depression and Anxiety issues',
        'Hindi, Urdu and Gujrati – speaking'
    ],
    educationParts: [
        'Master’s Degree in Rehabilitation',
        'Mental Health Counseling Degree from Florida International University'
    ],
    licenses: [
        'Licensed Mental Health Counselor',
        'Masters Certified Addiction Professional'
    ],
    experience: 'I have extensive experience working with youth and adults from various cultural backgrounds. I offer services to English, Hindi, Urdu, and Gujrati speaking clients. In addition to helping my clients find stability and fulfillment in their lives, I also spend time volunteering to help women and families. My cultural background has helped me understand issues in immigrant families with second generation children. I also have experience and interest in treating depression, anxiety, ADHD, domestic violence, and substance abuse issues.',
    approaches: [
        'Cognitive Behavioral Therapy',
        'Motivational Interviewing',
        'Relaxation techniques',
        'Meditation',
        'Psycho-education'
    ],
    additionalQualifications: [
        'Member of American Counseling Association (ACA)',
        'Member of Associations of Florida Colleges'
    ],
    bookingInfoParts: [
        '45 minute weekly session or longer session.',
        'Pay by cash or credit card',
        '24 hour notice to cancel or reschedule appointments',
        `You can email me at ${common[THERAPIST_REHANA_VALUE].email}`
    ],
    image: Rehana
};

const KOBITTA_ABOUT_US_DATA = {
    specialties: [
        'Anxiety and Depression',
        'Feeling highly emotional, impulsive, self-harm/cutting or similar behaviors',
        'Addiction and Substance Use concerns',
        'Or just wanting to talk to someone regarding LGBT concerns or related issues',
        'I provide services in English, Hindi and Urdu'
    ],
    educationParts: ['Master’s in Mental Health from FIU'],
    licenses: [
        'Licensed Mental Health Counselor',
        'Masters Certified Addiction Professional'
    ],
    experience: 'I have been in private practice for 2 years, working with teens and adults. While helping teenagers I like to collaborate with parents, school counselors, pediatricians and others to provide optimum level of care. I work at Inspirations for Youth and Family and have a great deal of experience working with teens.',
    approach: 'I am eclectic in my orientation and offer a holistic, integrative approach to client centered therapy, using cognitive behavioral therapy, motivational interviewing, and stress management. I like to incorporate Yoga and Meditation to manage anxiety, depression, panic etc. Yoga is an effective tool and a coping skill that works in conjunction with therapy. I also use the EFT tapping techniques, while teaching you the methods of pressure points to relieve stressors.',
    bookingInfoParts: [
        `You can email me at ${common[THERAPIST_KOBITTA_VALUE].email}`,
        `You can call me at ${common[THERAPIST_KOBITTA_VALUE].phone}`,
        'You can also find me on social media https://www.facebook.com/kchopra30/'
    ],
    image: Kobitta
};

const renderBulletList = (items) => {
    return (
        <ul>
            {items.map(item =>
                <li>
                    <Typography body>
                        {item}
                    </Typography>
                </li>
            )}
        </ul>
    )
};

const renderSectionHeader = (header) => {
    return (
        <Typography variant={"h4"}>
            {header}
        </Typography>
    )
};

const renderHeaderAndText = (header, text) => {
    return (
        <div>
           {renderSectionHeader(header)}

            <Typography paragraph>
                {text}
            </Typography>
        </div>
    )
};

const renderHeaderAndList = (header, listItems) => {
    return (
        <div>
            {renderSectionHeader(header)}

            {renderBulletList(listItems)}
        </div>
    )
};

export const AboutUsBase = (props) => {
    const classes = useStyles();

    const {
        therapist
    } = props;

    const aboutUsData = therapist === THERAPIST_REHANA_VALUE ? REHANA_ABOUT_US_DATA : KOBITTA_ABOUT_US_DATA;

    return (
        <Box className={classes.pageContainer}>
            <Grid container direction={'column'}>
                <Typography variant={"h3"}>
                    {common[therapist].title}
                </Typography>

                {aboutUsData.introduction &&
                    renderHeaderAndText(texts.introductionHeader, aboutUsData.introduction)
                }

                {aboutUsData.specialties &&
                    renderHeaderAndList(texts.specialtiesHeader, aboutUsData.specialties)
                }

                {renderHeaderAndList(texts.educationHeader, aboutUsData.educationParts)}

                {renderHeaderAndList(texts.licensesHeader, aboutUsData.licenses)}

                {renderHeaderAndText(texts.experienceHeader, aboutUsData.experience)}

                {aboutUsData.approach &&
                    renderHeaderAndText(texts.approachHeader, aboutUsData.approach)
                }

                {aboutUsData.approaches &&
                    renderHeaderAndList(texts.approachHeader, aboutUsData.approaches)
                }

                {aboutUsData.additionalQualifications &&
                    renderHeaderAndList(texts.additionalQualificationsHeader, aboutUsData.additionalQualifications)
                }

                {aboutUsData.bookingInfoParts &&
                    renderHeaderAndList(texts.bookingInfoHeader, aboutUsData.bookingInfoParts)
                }

            </Grid>
        </Box>
    )
};