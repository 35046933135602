import React from 'react';
import { ContactUsForm } from './ContactUsForm';
import { makeStyles } from '@material-ui/core/styles';
import { common, THERAPIST_KOBITTA_VALUE, THERAPIST_REHANA_VALUE } from '../consts';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
    pageContainer: {
        [theme.breakpoints.up('md')]: {
            paddingTop: 100
        },
    },
    contentAndFormContainer: {
        marginTop: 35,
        marginBottom: 50
    },
    emailLink: {
        color: 'rgb(28, 159, 188)',
        paddingRight: 5,
    },
    phoneLink: {
        color: 'rgb(28, 159, 188)',
        borderLeft: '1px solid grey',
        paddingLeft: 5,
    },
    address: {
        marginTop: 5
    }
}));

const ContactInfo = ({title, email, phone, address}) => {
    const classes = useStyles();

    return (
        <Grid item lg={12} md={6} spacing={"space-between"}>
            <Typography variant={"h4"}>{title}</Typography>
            <Box>
                <a href={`mailto:${email}`} className={classes.emailLink}>{email}</a>
                <a href={`tel:${phone}`} className={classes.phoneLink}>{phone}</a>
            </Box>

            <Typography className={classes.address} >{address}</Typography>
        </Grid>
    );

};


export const ContactPage = () => {
    const classes = useStyles();

    return (
        <Box className={classes.pageContainer}>
            <Container maxWidth={"md"}>
                <Typography variant={"h3"} align={"center"}>
                    Contact Us Today!
                </Typography>
                <Typography paragraph align={"center"}>
                    Please use the form on this page to send a message. You may also call, or email anytime using the information below.
                </Typography>

                <Grid container spacing={3} justify={"space-evenly"} className={classes.contentAndFormContainer}>
                    <Grid container item spacing={4} lg={7} xs={12}>
                        <ContactInfo
                            title={common[THERAPIST_REHANA_VALUE].title}
                            email={common[THERAPIST_REHANA_VALUE].email}
                            phone={common[THERAPIST_REHANA_VALUE].phone}
                            address={common[THERAPIST_REHANA_VALUE].address}
                        />

                        <Grid item xs={12}>
                            <Box>
                                <iframe title="googleMapsEmbed" height="400" width={"100%"} frameBorder="0"  src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJBVWeQoAb2YgRqeimOYm4i6U&key=AIzaSyC6OUdb0QDEGn9b_Nkl89HHT0CuR4A3Ywo" allowFullScreen/>
                            </Box>
                        </Grid>

                        <ContactInfo
                            title={common[THERAPIST_KOBITTA_VALUE].title}
                            email={common[THERAPIST_KOBITTA_VALUE].email}
                            phone={common[THERAPIST_KOBITTA_VALUE].phone}
                            address={common[THERAPIST_KOBITTA_VALUE].address}
                        />

                        <Grid item xs={12}>
                            <Box>
                                <iframe title="googleMapsEmbed" height="400" width={"100%"} frameBorder="0"  src="https://www.google.com/maps/embed/v1/place?q=301%20NW%2084th%20Ave%20%23208&key=AIzaSyC6OUdb0QDEGn9b_Nkl89HHT0CuR4A3Ywo" allowFullScreen/>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid  item lg={5} xs={12}>
                        <Grid item xs={12}>
                            <Typography variant={"h4"}>Contact us set up an appointment</Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <ContactUsForm/>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
};
