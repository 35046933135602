import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';


const paragraphs = [
    <Typography variant={"h5"}>I’ve never talked to anyone. I’m used to handling things on my own. Aren’t people who go to therapy weak?</Typography>,
    <Box>Not at all. People who ask for help know when they need it and have the courage to reach out. Everyone needs help now and then. In our work together, I’ll help you explore and identify your strengths and how to implement them to reduce the influence of the problems you are facing.</Box>,
    <Typography variant={"h5"}>What’s the difference between talking to you or my best friend or family?</Typography>,
    <Box>The difference is between someone who can do something, and someone who has the training and experience to do that same thing professionally. A mental health professional can help you approach your situation in a new way– teach you new skills, gain different perspectives, listen to you without judgment or expectations, and help you listen to yourself. Furthermore, counseling is completely confidential. You won’t have to worry about others “knowing my business.” Lastly, if your situation provokes a great deal of negative emotion, and you’ve been confiding in a friend or family member, there is the risk that once you are feeling better you could start avoiding that person so you aren’t reminded of this difficult time in your life.</Box>,
    <Typography variant={"h5"}>Why shouldn’t I just take medication?</Typography>,
    <Box>Medication can be effective but it alone cannot solve all issues. Sometimes medication is needed in conjunction with counseling. Our work together is designed to explore and unpack the problems you are experiencing and expand on your strengths that can help you accomplish your personal goals.</Box>,
    <Typography variant={"h5"}>How does it work? What do I have to do in sessions?</Typography>,
    <Box>Because each person has different issues and goals for counseling, it will be different depending on the individual. I tailor my therapeutic approach to your specific needs.</Box>,
    <Typography variant={"h5"}>How long will it take?</Typography>,
    <Box>Unfortunately, this is not possible to say in a general FAQs page. Everyone’s circumstances are unique to them and the length of time counseling can take to allow you to accomplish your goals depends on your desire for personal development, your commitment, and the factors that are driving you to seek counseling in the first place.</Box>,
    <Typography variant={"h5"}>I want to get the most out of therapy. What can I do to help?</Typography>,
    <Box>I am so glad you are dedicated to getting the most out of your sessions. Your active participation and dedication will be crucial to your success.</Box>,
];

export const Faqs = () => {
    return (
        <Box>
            {paragraphs.map(p => <Typography paragraph>{p}</Typography>)}
        </Box>
    );
};