import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { TabManager } from '../tab/TabManager';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { AddictionCounseling } from './AddictionCounseling';
import { ContactSideBar } from '../ContactSideBar';
import { IndividualCounseling } from './IndividualCounseling';
import { CouplesCounseling } from './CouplesCounseling';
import { TeenCounseling } from './TeenCounseling';
import { ImmigrationServices } from './ImmigrationServices';
import { CounselingForStudents } from './CounselingForStudents';
import { Supervision } from './Supervision';


const useStyles = makeStyles(theme => ({
    pageContainer: {
        [theme.breakpoints.up('md')]: {
            paddingTop: 150
        },
        marginBottom: 50
    },
    sideBar: {
        marginTop: 25
    },
    content: {
        marginLeft: 20
    }
}));


const ServiceTabContent = ({children}) => {
    const classes = useStyles();

    return (
       <Box>
           <Grid container direction={'column'} className={classes.content}>
               {children}
           </Grid>
       </Box>
   )
};

const tabs = [
    {
        key: 'addiction',
        title: 'Addiction',
        path: '/services/addiction',
        component: <ServiceTabContent><AddictionCounseling/></ServiceTabContent>,
    },
    {
        key: 'individual',
        title: 'Individual',
        path: '/services/individual',
        component: <ServiceTabContent><IndividualCounseling/></ServiceTabContent>,
    },
    {
        key: 'couples',
        title: 'Couples',
        path: '/services/couples',
        component: <ServiceTabContent><CouplesCounseling/></ServiceTabContent>,
    },
    {
        key: 'teen',
        title: 'Teen',
        path: '/services/teen',
        component: <ServiceTabContent><TeenCounseling/></ServiceTabContent>,
    },
    {
        key: 'immigration',
        title: 'Immigration Services',
        path: '/services/immigration',
        component: <ServiceTabContent><ImmigrationServices/></ServiceTabContent>,
    },
    {
        key: 'students',
        title: 'Counseling for Students',
        path: '/services/students',
        component: <ServiceTabContent><CounselingForStudents/></ServiceTabContent>,
    },
    {
        key: 'supervision',
        title: 'Supervision',
        path: '/services/supervision',
        component: <ServiceTabContent><Supervision/></ServiceTabContent>,
    }
];

export const Services = (props) => {

    const classes = useStyles();

    return (
        <Box className={classes.pageContainer}>
            <Container maxWidth={"lg"}>
                <Grid container spacing={5} justify={"space-evenly"} scrollable>
                    <Grid item spacing={4} lg={8} xs={12} >
                        <Typography variant={"h3"}>
                            Counseling Services
                        </Typography>
                        <Typography paragraph>
                            We realize that no two people are the same and as such tailor our therapy and counseling services to meet the specific needs of our clients. Whether you are struggling with substance abuse, addiction or anything else, we will help you recognize underlying problems, fully realize your strengths and adjust certain behaviors and thoughts to see things a different, healthier way.
                        </Typography>
                        <Typography paragraph>
                            Our practice specializes in treating teens and adults. However, we also offer comprehensive services dealing with:
                        </Typography>

                        <Box>
                            <TabManager tabs={tabs} variant="scrollable" scrollButtons="auto" orientation="vertical" isVertical />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );

};
