import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';



const paragraphs = [
    <Box>Have you ever felt you should cut down on your addiction problem? Have people annoyed you by criticizing your addiction problem? Have you ever felt bad or guilty within yourself for drinking and/or taking drugs? Have you ever had a drink first thing in the morning to steady your nerves or to get rid of a hangover?</Box>,
    <Box>One “yes” answer suggests a possible alcohol and/or drug problem. More than one “yes” answer means there is a high chance that a problem exists.</Box>,
    <Box><strong>Natural Healing Broward provides Addiction Counseling.</strong> We are certified Addiction professionals who can tailor your treatment based on your needs.  We see our clients on an outpatient basis, up to three times a week.  We can meet with you, your family and other people who are involved in your treatment and offer affordable services to meet your needs.</Box>,
    <Box>In <strong>Addiction Counseling</strong>, you will receive support in the following: 12-step work, relapse prevention, identifying triggers, managing cravings, anxiety, depression, family therapy, coping skills and after care.  Our main goal is to help you achieve and maintain sobriety and get started on your journey towards recovery.</Box>,
    <Box>We treat substance abuse, alcoholism, marijuana usage and other drug addictions.  We work closely with local treatment centers, detoxes, residential treatment centers and IOP programs.  After meeting with you, we will develop an <strong>individualized treatment plan</strong> that will meet your needs.  We can also make recommendations for a higher level of care, if needed and indicated.  We can complete Substance Abuse assessments needs for court and probations orders. We can also provide a court-ordered substance abuse evaluation, if needed.</Box>,
    <Box>We are trained in Seeking Safety and Cannabis Youth Treatment.  Seeking Safety Treatment is an option that is an evidenced based practice for women who have experienced trauma and are substance abusers.  If you are a trauma survivor and have a history of substance use, Seeking Safety Treatment might be for you.  Call our office for a free consultation to see if Seeking Safety Treatment is a good fit for you.</Box>,
    <Box>If you have a teenager who is using marijuana, we can meet with them and your family to complete an assessment and make recommendations for treatment and follow-up.  We provide psycho-education to your family regarding marijuana usage and the effects of marijuana on teens.</Box>,
    <Box>We take a Natural Healing approach to treatment of substance abuse.  We believe in treating the mind, body, and soul.  We encourage healthy eating, exercise, yoga, Reiki, mindfulness, self-care and a variety of other strategies to keep you healthy.</Box>,
];

export const AddictionCounseling = () => {
    return (
        <Box>
            {paragraphs.map(p => <Typography paragraph>{p}</Typography>)}
        </Box>
    );
};