import React from 'react';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useEffect, useState } from 'react';


import imageOne from './resources/office-pic-2022-1.jpg';
import imageTwo from './resources/office-pic-2022-2.jpg';
import imageThree from './resources/office-pic-2022-3.jpg';
import imageFour from './resources/office-pic-2022-4.jpg';

const photos = [
    imageFour,
    imageOne,
    imageTwo,
    imageThree,
];


const useStyles = makeStyles(theme => {
    return {
        root: {
            backgroundColor: 'transparent'
        },
        outlined: {
            border: 'none'
        },
        photo: {
            width: '130%',
            clipPath: 'inset(0px 50px 0px 50px)',
            marginLeft: '-50px'
        }
    }
});


const CarouselItem = (props) => {
    const classes = useStyles();

    return (
        <div hidden={!props.active}>
            <Fade in={props.active} timeout={500}>
                <Paper square variant='outlined'
                       classes={{
                           root: classes.root,
                           outlined: classes.outlined,
                       }}
                       className={classes.item}
                >
                    <img className={classes.photo} alt="" src={props.item}/>
                </Paper>
            </Fade>
        </div>
    )
};

export const OfficePhotos  = () => {
    const [active, setActive] = useState(0);
    const interval = 3000; // 5 second

    const setIntervalCallback = () => {
        setActive((active + 1) % photos.length);
    };

    useEffect(() => {
        setTimeout(setIntervalCallback, interval);
    });

    return (
        <div>
            {photos.map((photo, index) =>
                <CarouselItem key={index} active={index === active} item={photo}/>
            )}
        </div>
    )
};
