import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';


const paragraphs = [
    <Typography variant={"h5"}>Fees</Typography>,
    <Box>Fees for assessments, including immigration reports, substance abuse assessments and other psychosocial assessments range from $150-450.</Box>,
    <Box>We offer Sliding Scale rates for those who qualify.</Box>,
    <Box>At Natural Healing Broward, we want to make getting mental health care affordable and accessible to you.</Box>,
    <Typography variant={"h5"}>Insurance</Typography>,
    <Box>Depending on your current health insurance provider or employee benefit plan, it is possible for services to be covered in full or in part. Please contact your provider to verify how your plan compensates you for psychotherapy services.</Box>,
    <Box>
        We’d recommend asking these questions to your insurance provider to help determine your benefits:
        <ul>
            <li>Does my health insurance plan include mental health benefits?</li>
            <li>Do I have a deductible? If so, what is it and have I met it yet?</li>
            <li>Does my plan limit how many sessions per calendar year I can have? If so, what is the limit?</li>
            <li>Do I need written approval from my primary care physician in order for services to be covered?</li>
        </ul>
    </Box>,
    <Typography variant={"h5"}>Payment We Accept</Typography>,
    <Box>
        <ul>
            <li>Cash</li>
            <li>Check</li>
            <li>Blue Cross</li>
            <li>Blue Shield</li>
            <li>Cigna</li>
            <li>ComPsych</li>
            <li>E4Health</li>
            <li>Employee Assistance Group (ESI)</li>
            <li>Florida Blue</li>
            <li>Humana</li>
            <li>Life Solution</li>
            <li>New Directions</li>
            <li>TRICARE</li>
            <li>Out of Network</li>
        </ul>
    </Box>,
    <Typography variant={"h5"}>Cancellation Policy</Typography>,
    <Box>If you are unable to attend a session, please make sure you cancel at least 24 hours beforehand. Otherwise, you may be charged for the full rate of the session.</Box>,
    <Typography variant={"h5"}>Any Other Questions</Typography>,
    <Box>Please contact us for any additional questions you may have. We look forward to hearing from you!</Box>,
];

export const FeesAndInsurance = () => {
    return (
        <Box>
            {paragraphs.map(p => <Typography paragraph>{p}</Typography>)}
        </Box>
    );
};