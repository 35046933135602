import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { sendContactUsEmail } from './sendContactUsEmail';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MuiPhoneNumber from "material-ui-phone-number";
import { THERAPIST_KOBITTA_VALUE, THERAPIST_REHANA_VALUE } from '../consts';

const useStyles = makeStyles(theme => ({
    therapistSelect: {
        minWidth: 167,
    },
    formBody: {
        backgroundColor: 'rgb(236, 245, 245)',
        padding: '10px 35px'
    },
    formItem: {
        width: '100%',
        marginBottom: 15
    },
    textInput:{
        width: '100%',
        marginBottom: 15
    },
    selectInput:{
        width: '100%',
        marginBottom: 15,
        marginTop: 15
    },
    textArea: {
        width: '100%',
        height: 150,
        marginBottom: 15,
    },
    submitButton: {
        width: '100%',
        marginBottom: 15,
        marginTop: 15
    },
    successAlert: {
        height: 50
    }
}));

export const ContactUsForm = (props) => {
    const classes = useStyles();

    const [formSaved, setFormSaved] = React.useState(false);

    const [therapist, setTherapist] = React.useState('');
    const handleTherapistChange = event => {
        setTherapist(event.target.value);
    };

    useEffect(() => {
        setTherapist(props.therapist);
    }, [props.therapist]);


    const [name, setName] = React.useState('');
    const handleNameChange = event => {
        setName(event.target.value);
    };

    const [number, setNumber] = React.useState('');
    const handleNumberChange = value => {
        setNumber(value);
    };

    const [email, setEmail] = React.useState('');
    const handleEmailChange = event => {
        setEmail(event.target.value);
    };

    const [message, setMessage] = React.useState('');
    const handleMessageChange = event => {
        setMessage(event.target.value);
    };

    const onSubmit = () => {
        sendContactUsEmail(therapist, name, number, email, message);
        setFormSaved(true);
    };

    if (formSaved) {
        return (
            <Alert severity="success" className={classes.successAlert}>
                Thanks for contacting us! We will be in touch with you shortly.
            </Alert>
        )
    }

    const THERAPIST_OPTIONS = [
        {
            value: THERAPIST_REHANA_VALUE,
            label: 'Rehana Saleem'
        },
        {
            value: THERAPIST_KOBITTA_VALUE,
            label: 'Kobitta Chopra'
        }
    ];

    return (
        <Box>
            <Grid container item className={classes.formBody}>
                <TextField
                    id={"name"}
                    label="Name"
                    value={name}
                    onChange={handleNameChange}
                    className={classes.textInput}
                />

                <TextField
                    id={"email"}
                    label="E-mail"
                    value={email}
                    onChange={handleEmailChange}
                    className={classes.textInput}
                />

                <MuiPhoneNumber
                    defaultCountry={'us'}
                    onChange={handleNumberChange}
                    id={"phone"}
                    label="Phone"
                    className={classes.textInput}
                />

                <FormControl className={classes.selectInput}>
                    <InputLabel id="therapist-select-label" className={classes.formItem}>
                        Choose a therapist
                    </InputLabel>

                    <Select
                        labelId="therapist-select-label"
                        id="therapist-select"
                        value={therapist}
                        onChange={handleTherapistChange}
                        className={classes.formItem}
                    >
                        {THERAPIST_OPTIONS.map(therapistOption => {
                            return (
                                <MenuItem value={therapistOption.value} key={therapistOption.value}>
                                    {therapistOption.label}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>

                <TextField
                    id={"comment"}
                    label="Comment or Message"
                    multiline
                    rows="4"
                    variant="outlined"
                    value={message}
                    onChange={handleMessageChange}
                    className={classes.textArea}
                />

                <Button variant="contained" color="primary" onClick={onSubmit} className={classes.submitButton}>
                    Send
                </Button>
            </Grid>
        </Box>
    )
};
