import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';


const paragraphs = [
    <Box>Natural Healing Broward, offers assessment and evaluation for immigration purposes.  Our evaluations are typically completed when you are seeking a waiver from citizenship for mental health services.  Our Immigration Evaluations include meeting with a Licensed Professional and will include an extensive evaluation related to the immigrant’s mental health concerns, especially related to immigration.  We routinely evaluate and assess: trauma, Major Depressive Disorder, Generalized Anxiety Disorder, PTSD and a variety of other issues.</Box>,
    <Box>We will work closely with your Immigration lawyer to ensure your evaluation is completed accurately.  Immigration evaluations are generally used for spousal case, extreme hardship, and political asylum cases.</Box>,
    <Box>During your evaluation, you will meet with us.  You will provide information regarding your current circumstances and how your immigration status is affecting your mental health.  We will provide you with a battery of assessments to complete.  You may be recommended to attend counseling.  We will provide you with follow-up recommendations to help you treat your mental health issues.</Box>,
    <Box>Our team has been working closely with local lawyers to help the needs of our clients.  We can communicate with your lawyer and provide all necessary reports, paperwork and information to them.  Not everyone will qualify for a waiver.</Box>,
];

export const ImmigrationServices = () => {
    return (
        <Box>
            {paragraphs.map(p => <Typography paragraph>{p}</Typography>)}
      </Box>
    );
};