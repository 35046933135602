import React from 'react';
import Box from '@material-ui/core/Box';
import { Carousel } from './Carousel';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { OfficePhotos } from './OfficePhotos';
import SelfIntroBackground from './resources/main2-copy.jpg';
import Rehana from '../resources/rehana.jpg';
import Kobitta from '../resources/kobitta.jpg';
import { common, THERAPIST_KOBITTA_VALUE, THERAPIST_REHANA_VALUE } from '../consts';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';
import { sections } from '../sections';

const texts = {
    welcomeHeader: 'Welcome to Natural Healing Broward!',
    welcomeBodyOne:
        'Life isn’t always easy. Sometimes, we need a little bit of help to work through some of life’s more challenging problems.',
    welcomeBodyTwo:
        'Everyone has hurdles to overcome at some point. Whether you struggle with anxiety, depression, or any other mental health issue, you should know that there is hope for a better tomorrow.',
    welcomeBodyThree:
        'You might be initially hesitant to seek out help and support for personal matters, but when you do, you will feel confident that you are taking a courageous first step towards getting to a better place and being a better you.',
    rehanaIntro:
        'Are you worried, frustrated, tired, have no energy and just want to stay in bed all day? Or just tired of not sleeping due to your racing thoughts? Are you afraid, sad and can’t control yourself? Are you currently living in a situation where your partner or other people in your life hurt you in a way that you feel uncomfortable, powerless, scared, hopeless, weak, humiliated and ashamed? There are millions of people that find themselves in these situations and seek professional help. I am trained professional who can understand your problems.',
    kobittaIntro:
        'Are you looking to get help for your teenager who might be struggling? I help teens and adults facing anxiety, depression, panic, deal with self harm, eating disorders, feelings of emotional abandonment , have better relationships, maintain sobriety and to live a life of happiness. I am eclectic in my orientation and offer a holistic, integrative approach to client centered therapy, using cognitive behavioral techniques, motivational interviewing, stress management, meditation and yoga practices which I learned growing up in the Himalayan mountains. I enjoy working with clients from multicultural backgrounds whether they are adults, teenagers, couples or families.',
    visitOurOffice:
    'Visit our office'

};

const useStyles = makeStyles(theme => {
    return {
        container: {
            marginBottom: '45px',
        },
        paragraph: {
            fontSize: '1.1rem'
        },
        selfIntro: {
            position: 'relative',
        },
        selfIntroContainer: {
            paddingTop: '40px',
            paddingBottom: '65px',
            position: 'relative'
        },
        selfIntroOverlay: {
            background: '#e4faff91',
            position: 'absolute',
            width: '100%',
            height: '100%',
        },
        selfIntroUnderlay: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: '-1',
            backgroundImage: `url(${SelfIntroBackground})`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%'
        },
        selfIntroBackgroundImage: {
            width: '100%',
        },
        mapContainer: {
            height: 500,
            position: 'relative',
            // paddingBottom: 280 // To offset the footer height
        },
        mapOverlay: {
            height: 500,
            position: 'absolute',
            width: '100%',
            background: 'rgba(255,255,255,0.5)'
        },
        mapOverlayContainer: {
            marginBottom: 50,
            background: '#626262',
            padding: 20,
        },
        mapOverlayContentRow: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 13,
            color: '#fff',
        },
        mapOverlayIcon: {
            marginRight: 4,
            color: '#A9BDCC',
        },
        rehanaImage: {
            height: 150
        },
        covidAlert: {
            [theme.breakpoints.up('md')]: {
                paddingTop: 100,
            },
            fontSize: 16
        },
        covidAlertTitle: {
            [theme.breakpoints.up('md')]: {
            },
            fontWeight: 'bold',
            fontSize: 20
        },
        covidAlertIcon: {
            [theme.breakpoints.up('md')]: {
            },
            display: 'none'
        },
        therapistOverlaysContainer: {
            display: 'flex'
        }
    }
});

export const Home = (props) => {
    const classes = useStyles();

    return (
        <Box>
            <Alert
                severity="info"
                className={classes.covidAlert}
                classes={{
                    icon: classes.covidAlertIcon,
                }}
            >
                <AlertTitle className={classes.covidAlertTitle}>Now Offering Teletherapy!</AlertTitle>

                We understand many people maybe social distancing due to COVID-19 and we want to help you.
                We offer confidential and secure online counseling to those who cannot make it to our office in Plantation Fl.
                We use a HIPAA compliant Telehealth Platform to offer you online counseling from the convenience of your home (or wherever you decide).

                <br/><br/>
                <a href={sections[sections.length -1].path}>Contact us now to get started!</a>
            </Alert>

            <Carousel/>

            <Container fixed className={classes.container} maxWidth="md" >
                <Typography variant="h2" align="center">{texts.welcomeHeader}</Typography>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <Typography paragraph className={classes.paragraph}>
                            {texts.welcomeBodyOne}
                        </Typography>
                        <Typography paragraph className={classes.paragraph}>
                            {texts.welcomeBodyTwo}
                        </Typography>
                        <Typography className={classes.paragraph}>
                            {texts.welcomeBodyThree}
                        </Typography>
                    </Grid>
                    <Grid item md={6}>
                        <OfficePhotos/>
                    </Grid>
                </Grid>
            </Container>

            <Box className={classes.selfIntro}>
                <Box className={classes.selfIntroOverlay}/>
                <Box className={classes.selfIntroUnderlay}>
                    {/*<img src={SelfIntroBackground} className={classes.selfIntroBackgroundImage}/>*/}
                </Box>
                <Container fixed className={classes.selfIntroContainer} maxWidth="md">
                    <Typography variant="h3" align="center">{common[THERAPIST_REHANA_VALUE].title}</Typography>
                    <Grid container spacing={3} align="center">
                        <Grid item md={2} xs={12}>
                            <img src={Rehana} alt={common[THERAPIST_REHANA_VALUE].title} className={classes.rehanaImage}/>
                        </Grid>
                        <Grid item md={10} xs={12}>
                            <Typography>
                                {texts.rehanaIntro}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Typography variant="h3" align="center">{common[THERAPIST_KOBITTA_VALUE].title}</Typography>
                    <Grid container spacing={3} align="center" >
                        <Grid item md={2} xs={12}>
                            <img src={Kobitta} alt={common[THERAPIST_KOBITTA_VALUE].title}/>
                        </Grid>
                        <Grid item md={10} xs={12}>
                            <Typography>
                                {texts.kobittaIntro}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    )
};
