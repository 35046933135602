import React from 'react';
import { common, THERAPIST_KOBITTA_VALUE, THERAPIST_REHANA_VALUE } from './consts';
import { ContactUsFormWithExternalLinks } from './contact-us/ContactUsFormWithExternalLinks';
import Box from '@material-ui/core/Box';
import { ExternalLinks } from './footer/ExternalLinks';
import { ContactUsForm } from './contact-us/ContactUsForm';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Kobitta from './resources/kobitta-about-us.jpg';
import Rehana from './resources/rehana.jpg';

const useStyles = makeStyles(theme => ({
    contactInfo: {
      marginBottom: 20
    },
    contactLink: {
        color: 'rgb(28, 159, 188)',
    },
    profilePhoto: {
        height: 400,
        marginBottom: 20,
        maxWidth: 350,
        alignSelf: 'center'
    }
}));


const ContactInfo = (props) => {
    const classes = useStyles();

    const { selectedTherapist, therapist } = props;

    const { title, phone, email, address } = common[therapist];

    if (selectedTherapist !== undefined && selectedTherapist !== therapist) {
        return null;
    }

    return (
        <Box className={classes.contactInfo}>
            <Typography variant={"h4"} align={"center"}>
                {title}
            </Typography>
            <Typography align={"center"}>
                <a href={`mailto:${email}`} className={classes.contactLink}>
                    {email}
                </a>
            </Typography>
            <Typography align={"center"} paragraph>
                <a href={`tel:${phone}`} className={classes.contactLink}>
                    {phone}
                </a>
            </Typography>

            <Typography align={"center"}>
                {address}
            </Typography>

            <Typography align={"center"}>
                <ExternalLinks therapist={therapist}/>
            </Typography>
        </Box>
    )
};

const photos = {
    [THERAPIST_REHANA_VALUE]: Rehana,
    [THERAPIST_KOBITTA_VALUE]: Kobitta
};

const ProfilePhoto = ({therapist}) => {

    const classes = useStyles();

    if (!therapist) {
        return null;
    }

    return (<img className={classes.profilePhoto} src={photos[therapist]} alt={common[therapist].title}/>)
};


export const ContactSideBar = (props) => {
    const { therapist } = props;
    console.log({therapist});

    return (
        <Box>
            <Grid container spacing={3} direction={"column"} justify={"space-evenly"}>
                <ProfilePhoto therapist={therapist} />
                <ContactInfo therapist={THERAPIST_REHANA_VALUE} selectedTherapist={therapist}/>
                <ContactInfo therapist={THERAPIST_KOBITTA_VALUE} selectedTherapist={therapist}/>

                <ContactUsForm therapist={therapist}/>
            </Grid>
        </Box>
)
};
