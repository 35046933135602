import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';


const paragraphs = [
    <Box>Life isn’t always easy. Sometimes, even the strongest people amongst us need a little bit of help to work through some of life’s more challenging problems.</Box>,
    <Box>Everyone has battles to fight and hurdles to overcome at some point. Whether you struggle with anxiety, depression, substance abuse, or any other issue, you should know that there is hope for a better tomorrow.</Box>,
    <Box>You might be initially hesitant to seek out help and support for personal matters, but when you do, you will feel confident that you are taking a courageous first step towards getting to a better place and being a better you.</Box>,
    <Box>We realize that no two people are the same, and we tailor our therapy to meet your specific needs as an individual. We love helping people understand the things that are holding them back with an in-depth approach. After recognizing underlying problems, clients can fully realize their strengths and adjust certain behaviors and thoughts to see things in a different and healthier way.</Box>,
    <Box>At <strong>Natural Healing Broward</strong>, your needs and goals for healing and growth are our top priority. It is understandable, if you are hesitant about seeking therapy for any reason, but therapy is now a common practice and it helps wide variety of people who face challenges.</Box>,
    <Box>
        Individual Counseling can help people who need management with:
        <ul>
            <li>Anxiety and depression</li>
            <li>Anger issues</li>
            <li>Mental Illnesses such as Bipolar disorder and others</li>
            <li>Chemical dependency</li>
            <li>Grief and loss</li>
            <li>Personal development</li>
            <li>Relationship issues</li>
            <li>Trauma</li>
        </ul>
    </Box>,
];

export const IndividualCounseling = () => {
    return (
        <Box>
            {paragraphs.map(p => <Typography paragraph>{p}</Typography>)}
      </Box>
    );
};