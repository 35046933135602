import React from 'react';
import { FooterTherapistInformation } from './FooterTherapistInformation';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { THERAPIST_KOBITTA_VALUE, THERAPIST_REHANA_VALUE } from '../consts';

const useStyles = makeStyles(theme => ({
    container: {
        height: 250,
        backgroundColor: '#91a5b4',
        paddingTop: 30,
        position: 'absolute',
        width: '100%',
        bottom: 0
    }
}));

export const Footer = () => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Container maxWidth="md">
                <Grid container spacing={5} justify={"space-evenly"}>
                    <Grid item>
                        <FooterTherapistInformation therapist={THERAPIST_REHANA_VALUE}/>
                    </Grid>

                    <Grid item>
                        <FooterTherapistInformation therapist={THERAPIST_KOBITTA_VALUE}/>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
};