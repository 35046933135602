import React from 'react';
import { TabManager } from '../tab/TabManager';
import { AboutUsBase } from './AboutUsBase';
import { THERAPIST_KOBITTA_VALUE, THERAPIST_REHANA_VALUE } from '../consts';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { ContactUsFormWithExternalLinks } from '../contact-us/ContactUsFormWithExternalLinks';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { ContactSideBar } from '../ContactSideBar';
import { AboutUsTabManager } from './AboutUsTabManager';

const tabs = [
    {
        key: THERAPIST_REHANA_VALUE,
        title: 'Rehana Saleem',
        path: '/about/rehana-saleem',
        component: <AboutUsBase therapist={THERAPIST_REHANA_VALUE} />,
    },
    {
        key: THERAPIST_KOBITTA_VALUE,
        title: 'Kobitta Chopra',
        path: '/about/kobitta-chopra',
        component: <AboutUsBase therapist={THERAPIST_KOBITTA_VALUE} />,
    }
];

const useStyles = makeStyles(theme => ({
    pageContainer: {
        paddingTop: 25,
        [theme.breakpoints.up('md')]: {
            paddingTop: 150
        },
        marginBottom: 50
    }
}));

export const AboutUs = (props) => {
    const classes = useStyles();
    // const therapist = THERAPIST_REHANA_VALUE;

    return (
        <Box className={classes.pageContainer}>
            <Container maxWidth={"md"}>
                <AboutUsTabManager tabs={tabs} />
            </Container>
        </Box>
    )
};
