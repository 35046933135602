import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { TabManager } from '../tab/TabManager';
import { AddictionCounseling } from '../services/AddictionCounseling';
import Grid from '@material-ui/core/Grid';
import { ContactSideBar } from '../ContactSideBar';
import { FeesAndInsurance } from './FeesAndInsurance';
import { ClientForms } from './ClientForms';
import { Faqs } from './Faqs';



const useStyles = makeStyles(theme => ({
    pageContainer: {
        paddingTop: 20,
        [theme.breakpoints.up('md')]: {
            paddingTop: 150
        },
        marginBottom: 50
    },
    tabBase: {
        marginTop: 25
    }
}));

const GettingStartedTabBase = ({ children }) => {
    const classes = useStyles();

    return (<Box className={classes.tabBase}>{children}</Box>);
};


const tabs = [
    {
        key: 'fees-and-insurance',
        title: 'Fees & Insurance',
        path: '/getting-started/fees-and-insurance',
        component: <GettingStartedTabBase><FeesAndInsurance/></GettingStartedTabBase>,
    },
    {
        key: 'client-forms',
        title: 'Client Forms',
        path: '/getting-started/client-forms',
        component: <GettingStartedTabBase><ClientForms/></GettingStartedTabBase>,
    },
    {
        key: 'faqs',
        title: 'FAQs',
        path: '/getting-started/faqs',
        component: <GettingStartedTabBase><Faqs/></GettingStartedTabBase>,
    }
];

export const GettingStarted = () => {
    const classes = useStyles();

    return (
        <Box className={classes.pageContainer}>
            <Container maxWidth={"md"}>
                <Grid container spacing={5} justify={"space-evenly"}>
                    <Grid item spacing={4} lg={7} xs={12} >
                        <Box>
                            <TabManager tabs={tabs}/>
                        </Box>

                    </Grid>
                    <Grid item lg={5} xs={12}>
                        <Box className={classes.sideBar}>
                            <ContactSideBar/>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
};
