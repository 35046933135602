import { Email } from '../../lib/smtp'
import { common, THERAPIST_KOBITTA_VALUE, THERAPIST_REHANA_VALUE } from '../consts';

const LINE_BREAK = '<br/><br/>';

const getEmailAddress = (therapist) => {
    const emails = [];

    if (therapist === THERAPIST_REHANA_VALUE) {
        emails.push(common[THERAPIST_REHANA_VALUE].email);
    } else if (therapist === THERAPIST_KOBITTA_VALUE) {
        emails.push(common[THERAPIST_KOBITTA_VALUE].email);
    }

    return emails;
};

const getEmailBody = (name, number, email, message) => {
    return `
        Client Name: ${name} ${LINE_BREAK}
        Client Number: ${number} ${LINE_BREAK}
        Client Email: ${email} ${LINE_BREAK}
        Client Message: ${message} ${LINE_BREAK}
        `
};

export const sendContactUsEmail = (therapist, name, number, email, message) => {
    Email.send({
        Host : "smtp.elasticemail.com",
        Username : "sadruddinsaleem17@gmail.com",
        Password : "FC540AE8212BFBA3A787FDB094AD91DA5C3E",
        To : getEmailAddress(therapist),
        From : "noreply@naturalhealingbroward.com",
        Subject : "New Client Request - Natural Healing Broward",
        Body : getEmailBody(name, number, email, message)
    })
};
