import React, { useEffect } from 'react';
import FacebookLogo from '../resources/facebook-logo.png';
import LinkedinLogo from '../resources/linkedin-logo.png';
import BetterHelpLogo from '../resources/betterhelp-logo.png';
import PTLogo from '../resources/PT-logo.svg';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { THERAPIST_REHANA_VALUE } from '../consts';

const useStyles = makeStyles(theme => ({
    externalLink: {
        marginLeft: -3,
        marginRight: -3
    },
    externalLogo: {
        height: 40,
    }
}));

const REHANA_EXTERNAL_LINK_DATA = {
    PTDataId: "260500",
    FBLink: "https://www.facebook.com/rsaleem0724/",
    LinkedinLink: 'https://www.linkedin.com/in/rehana-saleem-6a74b167/',
    BetterHelpLink: 'https://www.betterhelp.com/rehana-saleem/'
};

const KOBITTA_EXTERNAL_LINK_DATA = {
    PTDataId: "260499",
    FBLink: "https://www.facebook.com/kchopra30/",
    LinkedinLink: 'https://www.linkedin.com/in/kobitta-chopra-419ba035/',
    BetterHelpLink: 'https://www.betterhelp.com/kobitta-chopra/'
};

export const ExternalLinks = (props) => {
    const classes = useStyles();

    const {
        therapist
    } = props;

    const {
        PTDataId,
        FBLink,
        LinkedinLink,
        BetterHelpLink
    } = therapist === THERAPIST_REHANA_VALUE ? REHANA_EXTERNAL_LINK_DATA : KOBITTA_EXTERNAL_LINK_DATA;

    return (
        <Grid container spacing={2} justify="center" alignItems={"center"} wrap="nowrap">
            <Grid item className={classes.externalLink}>
                <a href={`https://www.psychologytoday.com/profile/${PTDataId}`} className={classes.PTLink}>
                    <img src={PTLogo} alt={'psychologyTodayLogo'}/>
                </a>
            </Grid>

            <Grid item className={classes.externalLink}>
                <a href={FBLink}>
                    <img src={FacebookLogo} className={classes.externalLogo} alt={'facebookLink'}/>
                </a>
            </Grid>

            <Grid item className={classes.externalLink}>
                <a href={LinkedinLink}>
                    <img src={LinkedinLogo} className={classes.externalLogo} alt={'linkedinLink'}/>
                </a>
            </Grid>

            <Grid item className={classes.externalLink}>
                <a href={BetterHelpLink}>
                    <img src={BetterHelpLogo} className={classes.externalLogo} alt={'betterHelpLink'}/>
                </a>
            </Grid>
        </Grid>

    )
};