
export const THERAPIST_REHANA_VALUE = 'rehana';
export const THERAPIST_KOBITTA_VALUE = 'kobitta';

export const common = {
    [THERAPIST_REHANA_VALUE]: {
        title: 'Rehana Saleem, LMHC, MCAP, QS',
        simpleTitle: 'Rehana Saleem',
        email: 'rsaleemlmhc@gmail.com',
        phone: '(786) 671-6474',
        address: '7145 NW 47th Way, Coconut Creek, Fl 33073'
    },
    [THERAPIST_KOBITTA_VALUE]: {
        title: 'Kobitta Chopra, LMHC, MCAP, QS',
        simpleTitle: 'Kobitta Chopra',
        email: 'kchopralmhc@gmail.com',
        phone: '(954) 613-2951',
        address: '300 S. Pine Island Road, Suite 214, Plantation, FL 33324'
    },
    practiceName: 'Natural Healing Broward',
};
