import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';


const paragraphs = [
    <Box>Congratulations!!! If you just graduated from high school and are looking to attend college, <strong>OR</strong> are a returning student after taking some time off and are concerned about adjusting to the new environment.</Box>,
    <Box>Our team here at Natural Healing Broward can make this transition easier for you.</Box>,
    <Box>
        <ul>
            <li><b>Anxiety</b> and worry about adjusting into a new environment</li>
            <li><b>Anxiety</b> about moving away from a comfortable home environment</li>
            <li>Did you just come back home from college and missing your friends we can help?</li>
            <li>Stress and Time Management issues</li>
            <li>Grades are not what you expected we can guide</li>
            <li>Need help with <b>choosing the right major</b> in college and not feeling overwhelmed with options</li>
            <li>Help with summer jobs</li>
            <li>We can help you with Networking skills</li>
        </ul>
    </Box>,
    <Box>We look forward to working with you.</Box>
    ,
];

export const CounselingForStudents = () => {
    return (
        <Box>
            {paragraphs.map(p => <Typography paragraph>{p}</Typography>)}
        </Box>
    );
};