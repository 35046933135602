import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';


const paragraphs = [
    <Box><strong>Natural Healing Broward</strong> offers Couples Counseling, Marriage Counseling and Pre-marital Counseling sessions for couples.</Box>,
    <Box>We will help you create the relationship you desire, despite patterns of anger, hurt and resentment that have been in place for a long period of time. Couples Counseling has strategies that helps couples stay together, work through their issues and develop new ways of connecting with one another. We have seen relationships transform even when things seem hopeless. We can help you bring back balance and harmony into your relationships: whether they are romantic, marriage, family, or parent/child.</Box>,
    <Box>
        <Typography variant={"h5"}>Common Couples Issues</Typography>
        <ul>
            <li>Getting into the same arguments</li>
            <li>Talking to each other with disrespect</li>
            <li>Reconnecting sexually</li>
            <li>Avoiding talking about important issues </li>
            <li>Affair recovery</li>
            <li>Intimacy and connection</li>
        </ul>
    </Box>,
    <Box>At Natural Healing Broward what our clients have found out that as balance in relationships is healed and restored, this new “way of living” completely transforms many aspects of their lives including their material wealth and abundance.</Box>,
    <Box>Our work involves identifying issues due to which the relationship stays “stuck” in marriage, career or any relationship and then helping you change those patterns. We can focus on as many topics as you like, working through them.</Box>,
    <Box>Our time together will be used to its fullest benefit.</Box>,
    <Box>Begin working towards happiness, satisfaction, fulfillment and connection in your relationships.</Box>,

];

export const CouplesCounseling = () => {
    return (
        <Box>
            {paragraphs.map(p => <Typography paragraph>{p}</Typography>)}
      </Box>
    );
};