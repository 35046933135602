import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';


const paragraphs = [
    <Box>At <strong>Natural Healing Broward</strong> we have a long history and experience working with teenagers. We understand that when parents are looking for information on how to help their child, it can cause a great deal of stress trying to find the right answers. We will be with you every step of the way and make sure your adolescent is getting the proper care needed.</Box>,
    <Box>
        <Typography variant={"h5"}>Does your teenager struggle with?</Typography>
        <ul>
            <li>Anxiety</li>
            <li>Depression</li>
            <li>Lack of motivation</li>
            <li>Lack Of respect for authority</li>
            <li>Panic attacks</li>
            <li>Suicidal ideation</li>
            <li>Self-Harm</li>
            <li>Social Skills</li>
            <li>Social Media</li>
            <li>College preparation</li>
            <li>Academic performance</li>
            <li>Bullying</li>
            <li>Sexual Identity</li>
            <li>Gaming addiction</li>
            <li>Substance abuse</li>
        </ul>
    </Box>,
    <Box>We help struggling teenagers address their underlying issues causing their destructive behavior. We also work with the school, and any other service provider that you are currently working with to ensure that your teenager gets the appropriate help needed.</Box>,
    <Box>Our experience comes from working at adolescent community and behavioral health agencies.</Box>,
    <Box>Our treatment approach is not “one size fits all”. Instead we use our expertise in the field and use natural and holistic approaches including yoga, meditation and Reiki. We also formulate treatment plans keeping the family in mind.</Box>,
    <Box>
        <Typography variant={"h5"}>Your teenager will learn</Typography>
        <ul>
            <li>Life skills to reach their personal goals</li>
            <li>How to cope with anxiety and panic</li>
            <li>Get relief from depression</li>
            <li>Time management</li>
            <li>Anger management</li>
            <li>Improvement in Relationships</li>
        </ul>
    </Box>,
    <Box>We are ready to work with you. Call or email us today on getting your teenager the help that they need.</Box>,

];

export const TeenCounseling = () => {
    return (
        <Box>
            {paragraphs.map(p => <Typography paragraph>{p}</Typography>)}
      </Box>
    );
};