import React from 'react';
import { NavBar } from './components/NavBar';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { sections } from './components/sections';
import { Footer } from './components/footer/Footer';
import makeStyles from '@material-ui/core/styles/makeStyles';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'Playfair Display SC'
    ].join(','),
    // fontSize: '1.1rem',
    h2: {
      fontFamily: 'Playfair Display SC',
      fontSize: '2rem',
      fontWeight: '600',
      marginBottom: '30px',
      marginTop: '25px'
    },
    h3: {
      fontFamily: 'Playfair Display SC',
      fontSize: '1.5rem',
      fontWeight: '600',
      marginBottom: '25px',
      marginTop: '25px'
    },
    h4: {
      fontFamily: 'Playfair Display SC',
      fontSize: '1.2rem',
      fontWeight: '600',
      marginBottom: '15px',
      // marginTop: '25px'
    },
    h5: {
      fontFamily: 'Playfair Display SC',
      fontSize: '1rem',
      fontWeight: '600',
      marginBottom: '15px',
      marginTop: '25px'
    }
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#336285',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#A9BDCC'
    }
  }
});

const useStyles = makeStyles(theme => ({
  App: {
    position: 'relative',
    minHeight: '90vh',
    paddingBottom: 280 // To offset the footer height
  }
}));

function App() {
  const classes = useStyles(theme);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className={classes.App}>
          <NavBar/>

          <Switch>
            {sections.map((section, idx) => (
                <Route exact={idx === 0} path={section.path} key={section.path}>
                  {section.component}
                </Route>
            ))}
          </Switch>

          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
