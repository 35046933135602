import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';


const paragraphs = [
    <Box>Are you a new graduate registered intern looking for a Qualified Supervisor in Broward County area?</Box>,
    <Box>We supervise Registered Interns for licensure for Mental Health Counseling with the state of Florida.</Box>,
    <Box>We love to help registered interns receive their license. Let us help you achieve your goals and become a Licensed Mental Health Counselor. We offer individual and group supervision conveniently located in Broward County area. We offer face to face supervision, as well as Skype sessions. All of our registered interns have gone on to get licensed and usually pass the licensure exam on the first try.</Box>,
    <Box>
        <Box>If you choose us as your supervisor, we will help you to:</Box>
        <ul>
            <li>Learn to become a skilled, ethical and heartfelt therapist</li>
            <li>To grow professionally</li>
            <li>To prepare for licensing exam</li>
            <li>To prepare to start or join a private practice</li>
            <li>To treat adults and teenagers, in a variety of settings</li>
            <li>Oversee the implementation of evidenced based treatment techniques</li>
            <li>Support your personal growth and development as a therapist</li>
            <li>To gain competence and confidence and fluidity in your abilities to counsel clients</li>
        </ul>
    </Box>,
    <Box>Please contact us today to learn more and get involved with our internship program and starting with a Qualified Supervisor in Broward. We look forward to meeting you!</Box>,
    <Box>Our fees are $80 for individual supervision and $60 for group supervision.</Box>,
];

export const Supervision = () => {
    return (
        <Box>
            {paragraphs.map(p => <Typography paragraph>{p}</Typography>)}
      </Box>
    );
};