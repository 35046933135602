import React from 'react';
import { ExternalLinks } from './ExternalLinks';
import { common } from '../consts';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LocationOnIcon from "@material-ui/icons/LocationOn";
import {Container} from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
    container: {
        color: '#2f4f6e'
    },
    contactInfo: {
        borderTop: '2px solid #336285',
        marginBottom: 15,
        paddingTop: 15
    },
    contactLink: {
        color: '#2f4f6e',
        textDecoration: 'none'
    },
    externalLinkContainer: {
        marginTop: 15
    },
    mapOverlayContentRow: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 3
    },
    mapOverlayIcon: {
        marginRight: 4,
    },
}));

export const FooterTherapistInformation = (props) => {
    const classes = useStyles();

    const {
        therapist
    } = props;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const align = !isMobile ? 'center' : 'left';

    return (
        <div className={classes.container}>
            <Box>
                <Typography align={"center"} variant={"h5"}>
                    {common[therapist].title}
                </Typography>
            </Box>
            <Box className={classes.contactInfo}>
                <Typography align="center">
                    <div className={classes.mapOverlayContentRow}>
                        <PhoneIcon className={classes.mapOverlayIcon}/>
                        {common[therapist].phone}
                    </div>
                </Typography>

                <Typography align="center">
                    <div className={classes.mapOverlayContentRow}>
                        <MailOutlineIcon className={classes.mapOverlayIcon}/>
                        {common[therapist].email}
                    </div>
                </Typography>

                <Typography align={align}>
                    <div className={classes.mapOverlayContentRow}>
                        <LocationOnIcon className={classes.mapOverlayIcon}/>
                        {common[therapist].address}
                    </div>
                </Typography>
            </Box>
            <Box className={classes.externalLinkContainer}>
                <ExternalLinks therapist={therapist} />
            </Box>

        </div>
    )
};
