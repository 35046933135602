import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ClientIntakeForm from './resources/intakeform.pdf';
import ChildIntakeForm from './resources/childintakeform.pdf';
import ConsentForm from './resources/consentform.pdf';
import AuthorizationForRelease from './resources/Authorization-for-Release-of-Information.pdf';

const paragraphs = [
    <Box>If you are new, please feel free to fill out these intake forms before our first session. These forms can also be filled out during the first session.</Box>,
    <Box><a href={ClientIntakeForm} target="_blank">Client Intake Form</a></Box>,
    <Box><a href={ChildIntakeForm} target="_blank">Client Intake Form for Children and Andolescents</a></Box>,
    <Box><a href={ConsentForm} target="_blank">Client Consent Form</a></Box>,
    <Box>At Natural Healing Broward, we want to make getting mental health care affordable and accessible to you.</Box>,
    <Box>If you would like us to collaborate your sessions with another mental health provider, fill out the following form. We will then help you make the necessary arrangements.</Box>,
    <Box><a href={AuthorizationForRelease} target="_blank">Authorization for Release of Information</a></Box>,
];

export const ClientForms = () => {
    return (
        <Box>
            {paragraphs.map(p => <Typography paragraph>{p}</Typography>)}
        </Box>
    );
};
