import React, { useState, useEffect } from 'react';
import { Fade, Paper, Typography } from '@material-ui/core';
import { sections } from '../sections';

import imageOne from './resources/1.jpg';
import imageTwo from './resources/2.jpg';
import imageThree from './resources/3.jpg';
import imageFour from './resources/newmain.jpg';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const photos = [
    imageFour,
    imageOne,
    imageTwo,
    imageThree,
];


const texts = {
    aNewDay: 'A NEW DAY AWAITS YOU',
    dontWait: 'DON\'T WAIT TO GET THE HELP YOU NEED',
    contactUs: 'Contact Us'
};

const useStyles = makeStyles(theme => {
    return {
        root: {
            backgroundColor: 'transparent'
        },
        outlined: {
            border: 'none'
        },
        item: {
            position: 'sticky',
            width: '100%',
            zIndex: -1
        },
        photo: {
            width: '100%'
        },
        overlay: {
            position: 'absolute',
            width: '100%',
            top: '10%',
            zIndex: 0,
            color: '#fff',
        },
        overlayHeader: {
            textShadow: '1px 1px 5px #000c',
            fontWeight: '900',
            fontSize: '60px',
            fontFamily: 'Playfair Display SC',
            lineHeight: '100px',
        },
        overlaySubHeader: {
            fontWeight: '700',
            textShadow: '1px 1px 3px #000c',
            fontFamily: 'Playfair Display SC',
            fontSize: '20px',
        },
        overlayButton: {
            marginTop: '50px',
            [theme.breakpoints.down('sm')]: {
               marginTop: '175px',
            },
        }
    }
});



const CarouselOverlay = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <Typography align="center" className={classes.overlay}>
            {isLarge && (
                <>
                    <Box className={classes.overlayHeader}>
                        {texts.aNewDay}
                    </Box>
                    <Box className={classes.overlaySubHeader}>
                        {texts.dontWait}
                    </Box>
                </>
            )}
            <Box className={classes.overlayButton}>
                <Button variant="contained" size="large" color="primary" component="a" href={sections[sections.length -1].path}>
                    {texts.contactUs}
                </Button>
            </Box>
        </Typography>
    );
};

const CarouselItem = (props) => {
    const classes = useStyles();

    return (
        <div hidden={!props.active}>
            <CarouselOverlay/>
            <Fade in={props.active} timeout={500}>
                <Paper square variant='outlined'
                       classes={{
                           root: classes.root,
                           outlined: classes.outlined,
                       }}
                       className={classes.item}
                >
                    <img className={classes.photo} alt="" src={props.item}/>
                </Paper>
            </Fade>
        </div>
    )
};

export const Carousel  = () => {
    const [active, setActive] = useState(0);
    const interval = 5000; // 5 second

    const setIntervalCallback = () => {
        setActive((active + 1) % photos.length);
    };

    useEffect(() => {
        setTimeout(setIntervalCallback, interval);
    });

    return (
        <div>
            {photos.map((photo, index) =>
                <CarouselItem key={index} active={index === active} item={photo}/>
            )}
        </div>
    )
};
