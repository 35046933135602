import React from 'react';
import { Home } from './home/Home';
import { AboutUs } from './about-us/AboutUs';
import { ContactPage } from './contact-us/ContactPage';
import { Services } from './services/Services';
import { GettingStarted } from './getting-started/GettingStarted';
import { AddictionCounseling } from './services/AddictionCounseling';
import { IndividualCounseling } from './services/IndividualCounseling';
import { CouplesCounseling } from './services/CouplesCounseling';
import { TeenCounseling } from './services/TeenCounseling';
import { ImmigrationServices } from './services/ImmigrationServices';
import { CounselingForStudents } from './services/CounselingForStudents';
import { Supervision } from './services/Supervision';
import { FeesAndInsurance } from './getting-started/FeesAndInsurance';
import { ClientForms } from './getting-started/ClientForms';
import { Faqs } from './getting-started/Faqs';

export const sections = [
    {
        title: 'Home',
        path: '/',
        component: <Home/>,
    },
    {
        title: 'About Us',
        path: '/about',
        component: <AboutUs/>,
        subTabs: [
            {
                title: 'Rehana Saleem',
                path: '/about/rehana-saleem'
            },
            {
                title: 'Kobitta Chopra',
                path: '/about/kobitta-chopra'
            }]
    },
    {
        title: 'Services',
        path: '/services',
        component: <Services/>,
        subTabs: [
            {
                title: 'Addiction',
                path: '/services/addiction'
            },
            {
                title: 'Individual',
                path: '/services/individual',
            },
            {
                title: 'Couples',
                path: '/services/couples'
            },
            {
                title: 'Teen',
                path: '/services/teen'
            },
            {
                title: 'Immigration Services',
                path: '/services/immigration'
            },
            {
                title: 'Counseling for Students',
                path: '/services/students'
            },
            {
                title: 'Supervision',
                path: '/services/supervision'
            }]
    },
    {
        title: 'Getting Started',
        path: '/getting-started',
        component: <GettingStarted/>,
        subTabs: [
            {
                title: 'Fees & Insurance',
                path: '/getting-started/fees-and-insurance'
            },
            {
                title: 'Client Forms',
                path: '/getting-started/client-forms'
            },
            {
                title: 'FAQs',
                path: '/getting-started/faqs'
            }]
    },
    // {
    //     title: 'Resources',
    //     path: '/resources',
    //     component: <div>Resources</div>
    // },
    {
        title: 'Contact Us',
        path: '/contact',
        component: <ContactPage />
    }
];