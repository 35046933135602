import React from 'react';
import { common, THERAPIST_KOBITTA_VALUE, THERAPIST_REHANA_VALUE } from './consts';
import { sections } from './sections';
import { Toolbar, Typography, Link, Grid } from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { Link as RouteLink } from "react-router-dom";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import AppBar from '@material-ui/core/AppBar';


const useStyles = makeStyles(theme => {
    return {
        navBar: {
            backgroundColor: '#6A7680',
            [theme.breakpoints.up('md')]: {
                position: 'fixed',
            },
            zIndex: '10',
        },
        navBarTitle: {
            color: `${grey[50]}`,
            [theme.breakpoints.up('md')]: {
                width: '550px',
                height: '72px',
            },
            flex: '0 0 auto',
            cursor: 'pointer'
        },
        // This does not follow the theme because it's a special case
        practiceName: {
            borderBottom: `2px solid ${grey[900]}`,
            fontFamily: 'Playfair Display SC',
            fontStyle: 'normal',
            fontWeight: '700',
            [theme.breakpoints.up('md')]: {
               fontSize: '33px',
            },
            textShadow: '1px 1px 3px #000c',
        },
        practitionerTitle: {
            textAlign: 'center',
            marginTop: '5px',
            lineHeight: 1,
            [theme.breakpoints.up('md')]: {
                '&:first-of-type': {
                    'border-right': `2px solid ${grey[900]}`,
                    paddingLeft: '20px',
                    paddingRight: '20px'
                },
            },
            textShadow: '1px 1px 2px #000c',
        },
        practitionerTitlesContainer: {
            [theme.breakpoints.down('sm')]: {
                'flexDirection': 'column'
            },
            display: 'flex',
            justifyContent: 'space-between'
        },
        navBarLinks: {
            justifyContent: 'space-between',
            overflowX: 'auto',
            [theme.breakpoints.up('lg')]: {
               height: '90px',
            },
        },
        toolbarLink: {
            color: '#fff',
            textShadow: '1px 1px 2px #000c',
            padding: theme.spacing(2),
            flexShrink: 0,
        }
    }
});

const PracticeHeader = () => {
    const classes = useStyles();

    const headerOnClick = () => {
        window.location.href = '/';
    };

    return (
        <Grid container className={classes.navBarTitle} onClick={headerOnClick}>
            <Grid item xs={12}>
                <Typography align="center" className={classes.practiceName}>
                    {common.practiceName}
                </Typography>
            </Grid>
            <Grid container item xs={12} justify="space-between" className={classes.practitionerTitlesContainer}>
                <Grid item s={6} className={classes.practitionerTitle}>
                    {common[THERAPIST_REHANA_VALUE].title}
                </Grid>
                <Grid item s={6} className={classes.practitionerTitle}>
                    {common[THERAPIST_KOBITTA_VALUE].title}
                </Grid>
            </Grid>
        </Grid>
    )
};

const NavLink = React.forwardRef((props, ref) => {
    const {
        href,
        className,
        subTabs,
        children
    } = props;

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isOpen, setOpen] = React.useState(false);

    const closeMenu = () => {
        setOpen(false);
    };

    const onMouseOver = (event) => {
        console.log("onMouseOver")
        openMenu(event);
    }

    const openMenu = (event) => {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClick = (event) => {
        console.log("onClick")
        setOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        closeMenu();
    };

    const onMenuItemClick = (subTab) => {
        handleClose();
        window.location.href = subTab.path;
    };

    const StyledMenu = withStyles({
        paper: {
            // border: '1px solid #d3d4d5',
            boxShadow: '1px 1px 5px 3px #58585829'
        },
    })((props) => (
        <Menu
            {...props}
        />
    ));

    return (
        <div>
            <RouteLink
                ref={ref}
                to={subTabs ? undefined : href}
                className={className}
                onClick={handleClick}
                aria-controls="simple-menu"
                aria-haspopup="true"
            >
                {children}
            </RouteLink>

            {subTabs && (
                <StyledMenu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={isOpen}
                    onClose={handleClose}
                    onRequestClose={handleClose}
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    MenuListProps={{ onMouseLeave: handleClose }}
                >
                    {subTabs.map(subTab => {
                        return <MenuItem onClick={() => onMenuItemClick(subTab)}>{subTab.title}</MenuItem>
                    })}
                </StyledMenu>
            )}
        </div>
    );
});


export const NavBar = () => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid container className={classes.navBar}>
                <PracticeHeader />

                <Toolbar component="nav" className={classes.navBarLinks}>
                    {sections.map(section => (
                        <div>
                            <Link
                                className={classes.toolbarLink}
                                key={section.title}
                                variant="h6"
                                noWrap
                                component={NavLink}
                                href={section.path}
                                subTabs={section.subTabs}
                            >
                                {section.title}
                            </Link>
                        </div>
                    ))}
                </Toolbar>
            </Grid>
        </React.Fragment>
    )
};
