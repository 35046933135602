import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, withStyles } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';

const TabPanel = (props) => {
    const { children, hidden, ...other } = props;

    if (hidden) {
        return null;
    }

    return (
        <Box role="tabpanel">
            {children}
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    rootVertical: {
        flexGrow: 1,
        display: 'flex'
    },
    selectedTab: {
        fontWeight: 'bold',
        backgroundColor: '#ecf5f5 !important'
    },
    tabRoot: {
        fontSize: 16,
        fontWeight: 'bold',
        borderBottom: '1px solid #1890FF'
    }
}));

const ColoredTabs = withStyles({
    indicator: {
        backgroundColor: '#1890ff',
    },
})(Tabs);

export const TabManager = (props) => {
    const classes = useStyles();

    const { tabs, isVertical, ...otherProps } = props;

    const [value, setValue] = React.useState(tabs[0]['key']);

    const handleChange = (event, newValue) => {
        const automaticallyLoadTab = tabs.find(tab => tab.key === newValue);

        setValue(newValue);

        if (automaticallyLoadTab) {
            window.history.pushState(automaticallyLoadTab.key, automaticallyLoadTab.title, automaticallyLoadTab.path);
        }
    };

    useEffect(() => {
        const automaticallyLoadTab = tabs.find(tab => tab.path === window.location.pathname);

        if (automaticallyLoadTab) {
            setValue(() => automaticallyLoadTab.key);
        }
    }, []);

    return (
        <Box className={isVertical ? classes.rootVertical : classes.root}>
            <Paper elevation={0}>
                <ColoredTabs value={value} onChange={handleChange} {...otherProps}>
                    {tabs.map(tab => (
                        <Tab
                            key={tab.key}
                            value={tab.key}
                            label={tab.title}
                            classes={{
                                selected: classes.selectedTab,
                                root: classes.tabRoot
                            }}
                        />
                    ))}
                </ColoredTabs>
            </Paper>

            {tabs.map((tab, idx) => (
                <TabPanel label={tab.title} hidden={value !== tab.key} key={tab.key}>
                    {tab.component}
                </TabPanel>
            ))}
        </Box>
    );
};